
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import CreateTicketModal from "@/components/modals/forms/CreateTicketModal.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Globals from "@/views/common/Globals.vue";
import { ElNotification } from "element-plus";
import { ElLoading } from 'element-plus'
import router from "@/router";
import store from "@/store";
interface ICustomer {
  issueId: string;
  issueKey: string;
  title: string;
  emailAddress: string;
  requestId: string;
  priority:string;
  // description: string;
  ticketStatus: string;
  createdDate: string;
  modifiedStatus:string;
}

export default defineComponent({
  name: "Support",
  mixins: [Globals],
  components: {
    Datatable,
    CreateTicketModal,
  },
  data() {
    return {
      show: false,
      ticketData: [],
      componentKey: false,
      orgId: "",
      searchKey: "",
      initTickets: [] as ICustomer[],
      searchFlag: true,
    };
  },

  methods: {
    searchItems() {
      this.ticketData["data"].splice(
        0,
        this.ticketData["data"].length,
        ...this.initTickets
      );
      if (this.searchKey !== "") {
        let results = [] as ICustomer[];
        for (let j = 0; j < this.ticketData["data"].length; j++) {
          if (this.searchingFunc(this.ticketData["data"][j], this.searchKey)) {
            results.push(this.ticketData["data"][j]);
          }
        }
        this.ticketData["data"].splice(
          0,
          this.ticketData["data"].length,
          ...results
        );
        if (this.ticketData["data"].length == 0 && this.searchFlag == true) {
          this.searchFlag = false;
          ElNotification({
            title: "Warning",
            message: "No Records Found",
            type: "warning",
            duration: 2000,
            position: "top-right",
          });
        } else if (this.ticketData["data"].length != 0) {
          this.searchFlag = true;
        }
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    displayData(data) {
      this.ticketData = JSON.parse(JSON.stringify(data));
      this.componentKey = true;
      if (this.ticketData.length != 0) {
        this.show = true;
      }
      this.initTickets.splice(
        0,
        this.ticketData["data"].length,
        ...this.ticketData["data"]
      );
    },

    showAllTickets() {
      this.componentKey = false;
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get("/support/tickets?orgId=" + this.globalData.organizationId)
        .then((data) => {
          loading.close()
          this.displayData(data.data);
        })   
        .catch((error) => {
          loading.close()
          console.log("catch");
          ElNotification({
              title: "Warning",
              message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
              type: "warning",
              position: "top-right",
            });
        });
    },

    ChangeT(test) {
      this.showMyTickets();
    },

    showMyTickets() {
      this.componentKey = false;
       const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      ApiService.get(
        "/support/tickets/user?orgId=" +
          this.globalData.organizationId +
          "&emailId=" +
          this.globalData.email
      )
        .then((data) => {
          loading.close()
          this.displayData(data.data);
          console.log(data.data)
          
          
          
        })
        .catch((error) => {
          loading.close()
        
          ElNotification({
              title: "Warning",
              message: JSON.stringify(
              error.response.data["errors"][0]["errorMessage"]
            ),
              type: "warning",
              position: "top-right",
            });
          console.log("catch");
        });
    },
  },

  mounted() {
    this.showMyTickets();
  },

  setup() {
    const checkedCustomers = ref([]);
    const tableHeader = ref([
      {
        name: "Ticket ID",
        key: "issueKey",
        sortable: true,
      },
      {
        name: "Title",
        key: "title",
      },
      {
        name: "Request ID",
        key: "requestId",
        sortable: true,
      },
      {
        name: "Priority",
        key: "priority",
        sortable: true,

      },
      {
        name: "User Id",
        key: "emailAddress",
        sortable: true,
      },
      {
        name: "Status",
        key: "ticketStatus",
      },
      {
        name: "Created Date",
        key: "createdDate",
        sortable: true,
      },

      {
        name: "Modified Status",
        key: "modifiedStatus",
        sortable: true,
      },

      // {
      //   name: "Description",
      //   key: "description",
      // },
      {
        name: "Details",
        key: "actions",
      },
    ]);

    onMounted(() => {
      let user = store.getters.currentUser;
       if(Object.keys(user).length==0)
      {
     router.push({ path: "/sign-in" });
      }
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Tickets", ["Support Center"]);
    });

    return {
      tableHeader,
      checkedCustomers,
    };
  },
});
